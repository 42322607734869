import gql from 'graphql-tag'

export default gql`{
  id
  email
  name
  photo
  status
  telephone
  user {
    role
    office
  }
}`