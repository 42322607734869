import gql from 'graphql-tag'
import partnerFragment from './PartnerFragment'

export const CREATE_PARTNER_MUTATION = gql`
  mutation CreatePartner($userInput: UserInput) {
    createPartner(userInput: $userInput) {
      ...${partnerFragment}
    }
  }
`

export const DELETE_PARTNER_MUTATION = gql`
  mutation DeletePartner($id: ID!) {
    deletePartner(id: $id) {
      id
    }
  }
`

export const UPDATE_PARTNER_MUTATION = gql`
  mutation UpdatePartner($userInput: UserInput) {
    updatePartner(userInput: $userInput) {
      ...${partnerFragment}
    }
  }
`
export const EMAILS_PARTNERS_MUTATION = gql`
  mutation sendEmailsToPartners {
    sendEmailsToPartners
  }
`