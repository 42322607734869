import gql from 'graphql-tag'
import roleFragment from './RoleFragment'
import partnerFragment from './PartnerFragment'

export const GET_ROLES_PARTNER_QUERY = gql`
  query GetRolesPartner {
    roles: getRolesPartner {
      ...${roleFragment}
    }
  }
`

export const PARTNERS_QUERY = gql`
  query Partners {
    partners: partners {
      ...${partnerFragment}
    }
  }
`